import styled from '@emotion/styled';

const QuoteDetailPageStyle = styled('div')`
    display: flex;
    flex-direction: column;
    background: #f2f2f2;
    margin-bottom: 40px;
    .mobileView {
        display: none;
    }
    .logoHeader {
        width: 234px;
        height: 86px;
    }
    .headerPage {
        background-color: #fff;
        padding: 43px 129px 29px 129px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .viewPromotion {
        width: 65%;
        height: 64px;
        color: #1a91ff;
        font-size: 21.333px;
        font-style: normal;
        font-family: Lato;
        font-weight: 900;
        line-height: 32.753px;
        border-radius: 6.299px;
        border: 2.286px solid #1a91ff;
        background: rgba(26, 145, 255, 0.1);
        display: flex;
        align-items: center;
    }
    .iconCoins {
        margin-right: 15px;
    }
    .contentBtnPromotion {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .headerTitle {
        /* width: 100%; */
        padding-top: 38px;
        padding-bottom: 38px;
        padding: 38px 129px 38px 129px;
    }
    .titleHeader {
        color: #000;
        font-size: 38px;
        font-style: normal;
        font-weight: 900;
        line-height: 45.302px;
    }
    .titleview {
        color: #666;
        font-size: 38px;
        font-style: normal;
        font-weight: 500;
        line-height: 45.302px;
    }
    .viewShowInfor {
        background: #fff;
        padding: 55px 129px 55px 129px;
        display: flex;
        justify-content: space-between;
    }
    .viewShowImage {
        background: #fff;
        padding: 55px 129px 0px 129px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .viewInforLeft {
        width: 69%;
    }
    .viewInforRight {
        width: 30%;
    }
    .imageGoft {
        width: 100%;
        border-radius: 6px;
        object-fit: cover;
    }
    .headerShowRole {
        background: #fff;
    }
    .viewRole {
        padding: 16px 129px 16px 129px;
        display: flex;
        justify-content: space-between;
        background-color: rgba(184, 138, 18, 0.1);
        align-items: center;
    }
    .viewBtnRole {
        display: flex;
        align-items: center;
    }
    .styleContentRole {
        color: #000;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.16px;
    }
    .styleRole {
        color: #000;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0.16px;
    }
    .btnSend {
        border: 2px solid #069;
        background: #069;
        margin-left: 16px;
    }
    .btnSend:hover {
        border: 2px solid #069;
        background: #069 !important;
    }
    .btnEdit {
        border-radius: 6px;
        border: 2px solid #069;
        background: #fff;
        color: #069;
    }
    .btnEdit:hover {
        border-radius: 6px;
        border: 2px solid #069 !important;
        background: #fff !important;
        color: #069 !important;
    }
    .btnReset {
        border-radius: 6px;
        border: 2px solid #069;
        background: #fff;
        color: #069;
        margin-right: 16px;
    }
    .btnReset:hover {
        border-radius: 6px;
        border: 2px solid #069 !important;
        background: #fff !important;
        color: #069 !important;
    }
    .editTitleHeader {
        padding: 10px;
        display: inline-flex;
        align-items: center;
        border: 2px solid #12b897;
    }
    .editTitleHeader {
        width: 100%;
    }
    .inputEditTitle {
        width: 100%;
        height: 42px;
        color: #000;
        font-size: 38px;
        font-weight: 600;
        line-height: 45.302px;
        letter-spacing: 0.38px;
    }
    .areaViewValidate{
        width: 100%;
        display: flex;
        justify-content: end;
        position: relative;
        bottom: 95px;
    }
    .validateView {
        width: 61px;
        height: 30px;
        color: #12b897;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        border: 1px solid #12b897;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(18, 184, 151, 0.1);
    }
    @media screen and (max-width: 1024px) {
        .viewPromotion {
        width: 65%;
        height: 64px;
        color: #1a91ff;
        font-size: 17.333px;
        font-style: normal;
        font-family: Lato;
        font-weight: 900;
        line-height: 32.753px;
        border-radius: 6.299px;
        border: 2.286px solid #1a91ff;
        background: rgba(26, 145, 255, 0.1);
        display: flex;
        align-items: center;
    }
    }
    @media screen and (max-width: 768px) {
        width: 100%;
        .headerPage {
            padding: 20px 40px 20px 40px;
            display: flex;
            align-items: center;
        }
        .logoHeader {
            display: flex;
            align-items: center;
        }
        .LogoIMG {
            width: 150px;
        }
        .viewPromotion {
            height: 46px;
        }
        .headerTitle {
            padding: 20px 40px 20px 40px;
        }
        .titleHeader {
            font-size: 29px;
        }
        .titleview {
            font-size: 29px;
        }
        .viewShowInfor {
            padding: 20px 40px 20px 40px;
        }
        .viewInforLeft {
            width: 62%;
        }
        .viewInforRight {
            width: 36%;
        }
    }
    @media screen and (max-width: 430px) {
        .mobileView {
            display: flex;
            flex-direction: column;
        }
        .deskTopView {
            display: none;
        }
        .headerShowRoleMobile {
            background: #fff;
            width: 100%;
        }
        .viewRoleMobile {
            background-color: rgba(184, 138, 18, 0.1);
            display: flex;
            padding: 16px;
            align-items: center;
            justify-content: space-between;
        }
        .viewBtnRoleMobile {
            width: 60%;
            display: flex;
            justify-content: end;
        }
        .viewContentRoleMobile {
            width: 37%;
        }
        .styleContentRoleMobile {
            color: #000;
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0.16px;
        }
        .styleRoleMobile {
            color: #000;
            font-size: 14px;
            font-weight: 700;
            letter-spacing: 0.16px;
        }
        .btnSendMobile {
            border: 2px solid #069;
            background: #069;
        }
        .btnSendMobile:hover {
            border: 2px solid #069;
            background: #069 !important;
        }
        .btnEditMobile {
            border-radius: 6px;
            border: 2px solid #069;
            background: #fff;
            color: #069;
            margin-right: 3px;
        }
        .btnEditMobile:hover {
            border-radius: 6px;
            border: 2px solid #069 !important;
            background: #fff !important;
            color: #069 !important;
        }
        .headerPageMobile {
            background-color: #fff;
            padding: 22px 24px 32px 24px;
        }
        .logoHeaderMobile {
            margin-bottom: 20px;
        }
        .LogoIMGMobile {
            width: 50%;
            height: auto;
        }
        .viewPromotionMobile {
            width: 100%;
            color: #1a91ff;
            font-size: 15px;
            font-style: normal;
            font-weight: 900;
            line-height: 32.753px;
            border-radius: 6.299px;
            border: 2px solid #1a91ff;
            background: rgba(26, 145, 255, 0.1);
            display: flex;
            align-items: center;
        }
        .contentBtnPromotionMobile {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-around;
        }
        .headerTitleMobile {
            padding: 22px 24px 22px 24px;
        }
        .titleHeaderMobile {
            color: #000;
            font-size: 20px;
            font-style: normal;
            font-weight: 900;
            line-height: 26px;
        }
        .editTitleHeader {
            width: 100%;
        }
        .inputEditTitle {
            width: 100%;
            font-size: 20px;
        }
        .editTitleHeaderMobile {
            padding: 10px;
            display: inline-flex;
            align-items: center;
            border: 2px solid #12b897;
        }
        .titleviewMobile {
            color: #666;
            font-size: 20px;
            font-weight: 500;
            line-height: 27px;
            letter-spacing: 0.2px;
            /* margin-top: 23px; */
        }
        .validateView {
            left: 266px;
        }
        .viewShowImageMobile {
            background: #fff;
            padding: 22px 24px 0px 24px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
        .imageGoftMobile {
            width: 100%;
            height: 224px;
            border-radius: 6px;
            object-fit: unset;
        }
        .viewShowInfor {
            background: #fff;
            padding: 22px 24px 22px 24px;
            display: flex;
            flex-direction: column;
        }
        .viewInforLeft {
            width: 100%;
        }
        .viewInforRight {
            width: 100%;
            margin-bottom: 26px;
        }
        .btnResetMobile {
            border-radius: 6px;
            border: 2px solid #069;
            background: #fff;
            color: #069;
            margin-right: 3px;
        }
        .btnResetMobile:hover {
            border-radius: 6px;
            border: 2px solid #069 !important;
            background: #fff !important;
            color: #069 !important;
        }
        .btnPhone {
            width: 100%;
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 22px 27px 40px 27px;
            border: 1px solid #e0e0e0;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
            position: fixed;
            bottom: 0;
        }
        .btnPhoneCall {
            width: 100%;
            height: 56px;
            border-radius: 6px;
            font-style: 16px;
            background: #12b897;
            font-weight: 900 !important;
        }
        .btnPhoneCall:hover {
            background: #12b999 !important;
        }
    }
`;
export default QuoteDetailPageStyle;
