import {
    faCircleUser,
    faFlag,
    faGolfBallTee,
    faUser,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import icons from 'src/assets/icon';
import images from 'src/assets/image';
import ModalComponent from 'src/components/modalCustom';
import ModalTouristDetination from '../ModalTouristDestination';
import IntineraryStandardStyle from './style';
import moment from 'moment';
import HotelCardComponent from 'src/components/hotelCard';
import FlightCardComponent from 'src/components/flightCard';
import CarHideCardComponent from 'src/components/carHideCard';
import TransferCard from 'src/components/tranferCard';

interface intineraryProps {
    dataItinerary?: any;
    dateNumber?: any;
}

const IntineraryStandardComponent: React.FC<intineraryProps> = ({
    dataItinerary,
    dateNumber,
}) => {
    const date = dataItinerary?.date || null;
    const accommodations = dataItinerary?.accommodations || null;
    const flight = dataItinerary?.flight || null;
    const carHire = dataItinerary?.carHire || null;
    const transfers = dataItinerary?.transfers || null;
    
    return (
        <IntineraryStandardStyle>
            <div className="titleDay1">
                Day {dateNumber}: {moment(date).format('ddd Do MMMM YYYY')}
            </div>
            {accommodations?.length > 0 && (
                <div>
                    {accommodations?.map((item: any, index: any) => {
                        return (
                            <HotelCardComponent dataHotel={item} key={index} />
                        );
                    })}
                </div>
            )}
            {/* <div className="reviewSeller">
                <div>
                    “I was here last month and there were some great places to
                    eat in the evening. Excellent steak in the main bar”
                </div>
                <div className="avatarSeller">
                    <FontAwesomeIcon className="avatar" icon={faCircleUser} />
                </div>
            </div> */}
            {flight && (
                <div>
                    <FlightCardComponent dataFlight={flight} />
                </div>
            )}
            {carHire && (
                <div>
                    <CarHideCardComponent dataCarHide={carHire} />
                </div>
            )}
            {transfers?.length > 0 && (
                <div>
                    {transfers?.map((item: any, index: any) => {
                        return <TransferCard dataTransfer={item} key={index} />;
                    })}
                </div>
            )}
            {/* <div className="hotelAndGoflView">
                <img
                    alt=""
                    className="imgHotelOrGofl"
                    src={images.hotelImage}
                />
                <div>
                    <div className="titleItem">
                        Round of Golf at The Brabazon{' '}
                    </div>
                    <div className="addressHotelOrGolf">
                        The Belfry, Warwickshire
                    </div>
                    <div className="contentInfor">
                        1 Round at The Brabazon for 8 golfers
                    </div>
                    <div className="discriptRoundOfGofl">
                        <div>
                            <FontAwesomeIcon
                                className="iconDetail"
                                icon={faFlag}
                            />
                        </div>
                        <div>14:00 on 14/08/2024</div>
                        <div>
                            {' '}
                            x4
                            <FontAwesomeIcon
                                className="iconDetail"
                                icon={faUser}
                            />
                        </div>
                    </div>
                    <div className="discriptRoundOfGofl">
                        <div>
                            <FontAwesomeIcon
                                className="iconDetail"
                                icon={faFlag}
                            />
                        </div>
                        <div>14:00 on 14/08/2024</div>
                        <div>
                            {' '}
                            x4
                            <FontAwesomeIcon
                                className="iconDetail"
                                icon={faUser}
                            />
                        </div>
                    </div>

                    <div className="discriptRoundOfGofl">
                        <div>
                            <FontAwesomeIcon
                                className="iconDetail"
                                icon={faGolfBallTee}
                            />
                        </div>
                        <div>14:10 on 14/08/2024</div>
                        <div>
                            x2
                            <FontAwesomeIcon
                                className="iconDetailGolf"
                                icon={faUser}
                            />
                        </div>
                    </div>
                </div>
                <div className="iconAndBtnItem">
                    <FontAwesomeIcon
                        className="iconHotelorGolf"
                        icon={faGolfBallTee}
                    />
                    <div className="btnSeeDetail" onClick={() => showModal()}>
                        See details
                    </div>
                </div>
            </div> */}
            {/* <div className="reviewSeller">
                <div>
                    “I picked an earlier tee time, since you mentioned you’ve
                    got the yips. I thought you might appreciate the extra time
                    and relaxation of a quieter course!”
                </div>
                <div className="avatarSeller">
                    <FontAwesomeIcon className="avatar" icon={faCircleUser} />
                </div>
            </div> */}
        </IntineraryStandardStyle>
    );
};

export default React.memo(IntineraryStandardComponent);
