import React from 'react';
import CarHideCardStyle from './style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar } from '@fortawesome/free-solid-svg-icons';

interface carHideCardProps {
    dataCarHide?: any;
}

const CarHideCardComponent: React.FC<carHideCardProps> = ({ dataCarHide }) => {

    const carHireName = dataCarHide?.name || '';
    const pickUp = dataCarHide?.pickUp || null;
    const timePickUp = pickUp?.time || '';
    const carType = dataCarHide?.carType || '';
    const NumOfPassenger = dataCarHide?.passenger || 0; 

    return (
        <CarHideCardStyle>
            <div className="traditionView">
                <div className="viewDetailPlanFlight">
                    <div className="headerCard">
                        <div className="titleFightPlan">{carHireName}</div>
                        <FontAwesomeIcon
                            className="iconTransportation"
                            icon={faCar}
                        />
                    </div>
                    <div>
                        <div className="detailPlanflight">
                            <FontAwesomeIcon
                                className="iconTransportation"
                                icon={faCar}
                            />
                            <div className="locationName">
                                Pick-up vehicle from {pickUp?.location} at{' '}
                                {timePickUp}
                            </div>
                        </div>
                    </div>
                    <div className="viewFlightinformation">
                        <div className="textFont">
                            Car type: {carType}
                        </div>
                        <div className="textFont">{NumOfPassenger} pax</div>
                    </div>
                </div>
            </div>
        </CarHideCardStyle>
    );
};

export default CarHideCardComponent;
