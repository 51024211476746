import { faTaxi, faUserGroup } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import TransferCardStyle from './style';
interface transferCardProps {
    dataTransfer?: any;
}

const TransferCard: React.FC <transferCardProps> = ({dataTransfer}) => {

    const nametransfer = dataTransfer?.carType || '';
    const numberOfPassenger = dataTransfer?.passenger || 0;
    const pickUp = dataTransfer?.pickUp || '';

    return (
        <TransferCardStyle>
            <div className="traditionView">
                <div className="viewDetailPlanFlight">
                    <div className="headerCard">
                        <div className="titleFightPlan">{nametransfer}</div>
                        <FontAwesomeIcon
                            className="iconTransportation"
                            icon={faTaxi}
                        />
                    </div>
                    <div>
                        <div className="detailPlanflight">
                            <FontAwesomeIcon
                                className="iconTransportation"
                                icon={faTaxi}
                            />
                            <div>
                                <div className="locationName">
                                    {`${pickUp?.location} To
                                    ${pickUp?.to} at ${pickUp?.time}`}
                                </div>
                                <div className="textTimeFont">
                                    {numberOfPassenger} Passengers{' '}
                                    <FontAwesomeIcon
                                        className="iconPassenger"
                                        icon={faUserGroup}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </TransferCardStyle>
    );
};

export default TransferCard;
