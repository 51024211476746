import React from 'react';
import FlightCardStyle from './style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faPlane,
    faPlaneArrival,
    faPlaneDeparture,
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

interface flightCardProps {
    dataFlight?: any;
}

const FlightCardComponent: React.FC<flightCardProps> = ({ dataFlight }) => {
    
    const nameFlight = dataFlight?.name || ' ';
    const departureFlight = dataFlight?.departure || null;
    const departureTimeFlight = dataFlight?.departure?.date || '';
    const arrivalFlight = dataFlight?.arrival || null;
    const arrivalTimeFlight = dataFlight?.arrival?.date || '';
    const airline = dataFlight?.airline || null;
    const numOfPassenger = dataFlight?.passenger || 0;
    const extra = dataFlight?.extra || null;

    return (
        <FlightCardStyle>
            <div className="traditionView">
                <div className="viewDetailPlanFlight">
                    <div className="headerCard">
                        <div className="titleFightPlan">{nameFlight}</div>
                        <FontAwesomeIcon
                            className="iconTransportation"
                            icon={faPlane}
                        />
                    </div>
                    <div>
                        <div className="detailPlanflight">
                            <FontAwesomeIcon
                                className="iconTransportation"
                                icon={faPlaneDeparture}
                            />
                            <div>
                                <div className="locationName">
                                    {departureFlight?.name} (
                                    {departureFlight?.code})
                                </div>
                                <div className="textTimeFont">
                                    Departing{' '}
                                    {moment(departureTimeFlight)
                                        .utcOffset('+05:30')
                                        .format('HH:mm')}
                                </div>
                            </div>
                        </div>
                        <div className="detailPlanflight">
                            <FontAwesomeIcon
                                className="iconTransportation"
                                icon={faPlaneArrival}
                            />
                            <div>
                                <div className="locationName">
                                    {arrivalFlight?.name} ({arrivalFlight?.code}
                                    ){' '}
                                </div>
                                <div className="textTimeFont">
                                    Arriving{' '}
                                    {moment(arrivalTimeFlight)
                                        .utcOffset('+05:30')
                                        .format('HH:mm')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="viewFlightinformation">
                        <div className="textFont">
                            Airline: {airline?.number} {airline?.operatedBy}
                        </div>
                        <div className="textFont">
                            Passengers: {numOfPassenger}
                        </div>
                        {!extra?.luggage && !extra?.golfBags ? (
                            ''
                        ) : (
                            <div className="textFont">
                                Extras:{' '}
                                {extra.luggage
                                    ? `1 piece of luggage x ${extra?.luggage}`
                                    : ''}{' '}
                                {extra.golfBags
                                    ? ` Golf bags x ${extra?.golfBags}`
                                    : ''}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </FlightCardStyle>
    );
};

export default FlightCardComponent;
